import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Resist processing tools`}</h4>
    <p>{`Resist processing tools, also called "tracks," coat photoresists on wafers (typically by spin-coating, which spins the wafer to spread deposited photoresist), develop them (dissolve portions hit by light), and bake them (harden undissolved photoresist to prepare for etching). Japan is the sole producer of the most advanced tracks for EUV and ArF immersion photolithography; Germany, South Korea, the United States, and China produce less advanced equipment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      